@tailwind base;
@tailwind components;
@tailwind utilities;





.slick-slide > div{
    margin: 0 10px;
    width: 350px;
}


.slik-slide > div{
  margin: 0 10px;
  width: 350px;
}
